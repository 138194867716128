<template>
  <div class="phone-login">
    <div class="logo">
      <el-image :src="ossUrl + '/images/logo-img/login-logo.png'" fit="scale-down"></el-image>
    </div>
    <div class="form-module">
      <div class="field-item">
        <div class="input-box">
          <div class="input-icon">
            <i class="iconfont icon-shouji"></i>
          </div>
          <!-- <el-input maxlength="11" v-model="phone" placeholder="手机号"></el-input> -->
          <el-autocomplete v-model="phone" :class="{'isIphone8Up': facility.ios_grade > 2}"
            :fetch-suggestions="function(e,cb) { cb(loginAccountQuerySearch(e,'phone'))}" placeholder="手机号"
            @change="inputChangeFun($event,'phone')" @select="autocompleteChangeFun">
            <template slot-scope="{ item }">
              <div>{{ item.phone }}</div>
            </template>
          </el-autocomplete>
        </div>
      </div>
      <div class="field-item">
        <div class="input-box">
          <div class="input-icon">
            <i class="iconfont icon-suo"></i>
          </div>
          <el-input type="password" v-model="password" placeholder="密码">
            <!-- <i slot="suffix" class="el-input__icon el-icon-date"></i> -->
          </el-input>
        </div>
      </div>
      <div class="rest">
        <div class="record-password">
          <el-checkbox class="fake-checkbox" v-model="record_password">
            <span class="fake-span">记住密码</span>
          </el-checkbox>
        </div>
        <div class="forget-password">
          <!-- <router-link :to="{path: '/login/5'}">
            <span class="link-color">修改密码</span>
          </router-link>
          <span>/</span> -->
          <router-link :to="{path: '/login/4'}">
            <span class="link-color">忘记密码</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="confirm login">
      <el-button class="fake-btn" @click="clickLoginBtnFun">
        <span>登录</span>
      </el-button>
    </div>
    <div class="cot">
      <span>或</span>
    </div>
    <div class="confirm">
      <router-link :to="{path: '/login'}" v-if="clientSource !== 'baiduboxapp'">
        <el-button class="fake-btn">
          <i class="iconfont icon-weixin"></i>
          <span>微信登录</span>
        </el-button>
      </router-link>
      <div class="rest-control">
        <span>没有账户?&nbsp;&nbsp;</span>
        <router-link :to="{path: '/login/3'}">
          <span class="diff-style">去注册</span>
        </router-link>
      </div>
    </div>
    <div class="agreement">
      <agreement-temp :init-checked="login_checked" title="登录后代表您阅读并同意佰团乐"
        @agree-change="function(e) {login_checked = e.checked}" />
      <!-- <el-checkbox class="fake-checkbox" v-model="login_checked">
        <span class="fake-span">登录后代表您阅读并同意佰团乐<span class="diff-color">《用户协议》</span>和<span class="diff-color">《隐私协议》</span></span>
      </el-checkbox> -->
    </div>
  </div>
</template>

<script>
  import MyLocker from '@/utils/my_locker.js';
  const myLocker = new MyLocker;
  // import WxLogin from 'weixin-js-sdk';
  import agreementTemp from '@/views/login/components/agreement-temp.vue';
  export default {
    // 允许组件模板递归地调用自身
    name: 'qrcode-login',
    // 声明一组可用于组件实例中的组件
    components: {
      agreementTemp,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        clientSource: this.GLOBAL.clientSource,
        baseUrl: this.GLOBAL.baseUrl,
        ossUrl: this.GLOBAL.ossUrl,
        facility: {}, // 当前设备
        login_checked: true, // 是否同意登录
        phone: '', // 电话
        password: '', // 密码
        record_password: false, // 是否记住密码
        inputHistoryData: { // 历史输入数据
          login_account: [],
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 初始化账号表单
       */
      initAccountFormFun() {
        this.phone = ''; // 电话
        this.password = ''; // 密码
        this.record_password = false; // 是否记住密码
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 初始化历史输入
        this.initInputHistory();
        // 获取设备信息
        this.getFacilityInfo();
      },
      /**
       * 获取设备信息
       */
      getFacilityInfo() {
        let facility = this.myGetCurrentFacility();
        console.log('获取设备信息 facility == ', facility)
        this.facility = facility;
      },
      /**
       * 输入框监听变化
       */
      inputChangeFun(event, name) {
        console.log('输入框监听变化 event == ', event)
        console.log('输入框监听变化 name == ', name)
        console.log('输入框监听变化 this.phone == ', this.phone)
        let login_account = this.inputHistoryData.login_account || [];
        let item = login_account.find(item => item.phone == event);
        if (item) {
          // 历史输入-历史账号-选择变换
          this.autocompleteChangeFun(item);
        } else {
          // 初始化账号表单
          this.initAccountFormFun();
          this.phone = event;
        }
      },
      /**
       * 初始化历史输入
       */
      initInputHistory() {
        // 设置缓存-清空
        // this.setCachedFun(false,[]);

        // 获取历史输入-历史账号
        let input_history = this.getCachedFun();
        console.log('获取历史输入 input_history == ', input_history)
        if (input_history) {
          let {
            target = []
          } = input_history;
          this.inputHistoryData.login_account = target;
        }

        console.log('获取历史输入 this.inputHistoryData == ', this.inputHistoryData)
      },
      /**
       * 手机号-历史输入-输入建议
       */
      loginAccountQuerySearch(queryString, key) {
        // console.log('手机号-历史输入-输入建议 queryString == ',queryString)
        // console.log('手机号-历史输入-输入建议 key == ',key)
        var login_account = this.inputHistoryData.login_account || [];
        var results = queryString ? login_account.filter(this.createFilter(queryString, key)) : login_account;
        return results;
      },
      /**
       * 历史输入-输入建议-过滤
       */
      createFilter(queryString, key) {
        return (restaurant) => {
          return (restaurant[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      /**
       * 历史输入-历史账号-选择变换
       */
      autocompleteChangeFun(item) {
        let {
          phone,
          password,
          record_password,
          encrypt
        } = item;
        let fill_password,
          fill_record_password;

        this.phone = phone;
        // 如果记住了密码
        if (record_password) {
          if (encrypt) {
            encrypt = JSON.parse(JSON.stringify(encrypt));
            let {
              data,
              key,
              nonce
            } = encrypt; // 加密的数据
            // fill_password = myLocker.aesDecrypt(data, key, nonce); // 解密
            let decrypted = myLocker.aesDecrypt(data, key, nonce); // 解密
            fill_password = decrypted // 解密
          } else {
            fill_password = password
          }
          // console.log('解密 == ',fill_password)
          fill_record_password = record_password;
        } else {
          fill_password = '';
          fill_record_password = false;
        }
        this.password = fill_password;
        this.record_password = fill_record_password;
      },
      /**
       * 设置缓存
       */
      setCachedFun(key, value) {
        key = key || this.STORAGE.input_history.child_name['login_account']
        // 存储输入历史
        this.mySetStorageInputHistory({
          name: key,
          value: value,
        });
      },
      /**
       * 获取缓存
       */
      getCachedFun(key) {
        key = key || this.STORAGE.input_history.child_name['login_account']
        console.log('获取缓存 key == ', key)
        let data = this.myGetStorageInputHistory(key);
        console.log('获取缓存 data == ', data)
        return data;
      },
      /**
       * 记住密码
       */
      recordPasswordFun() {
        let {
          phone,
          password,
          record_password
        } = this;
        // phone: '', // 电话
        // password: '', // 密码
        // record_password: false, // 是否记住密码
        let encrypt_password = encodeURIComponent(password);
        // 获取加密数据
        let encrypt = myLocker.aesEncrypt(password);
        console.log('加密 encrypt == ', encrypt)

        // 获取缓存
        let login_account_history = this.getCachedFun() || {};
        console.log('记住密码 获取历史输入 login_account_history == ', login_account_history)

        let {
          target = []
        } = login_account_history;
        console.log('记住密码 获取历史输入 target == ', target)
        if (Array.isArray(target) && target.length > 0) {
          let old = target.find(item => item.phone == phone);
          if (old) {
            old.password = record_password ? encrypt.data : '';
            old.record_password = record_password;
            old.encrypt = record_password ? encrypt : {}; // 加密数据
          } else {
            target.push({
              phone: phone, // 电话
              password: record_password ? encrypt.data : '', // 密码
              record_password: record_password, // 是否记住密码
              encrypt: record_password ? encrypt : {}, // 加密数据
            })
          }
        } else {
          target.push({
            phone: phone, // 电话
            password: record_password ? encrypt_password : '', // 密码
            record_password: record_password, // 是否记住密码
            encrypt: record_password ? encrypt : {}, // 加密数据
          })
        }

        // 设置缓存
        this.setCachedFun(false, target);
        // 初始化历史输入
        this.initInputHistory();
      },
      /**
       * 告知变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 2, // 1/成功 2/取消 3/其他
          message: '取消',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-common-change', result)
      },
      /**
       * 点击登录按钮
       */
      clickLoginBtnFun() {
        // 校验数据
        let flag = this.verifyDataFun();
        if (!flag) return;

        // 发起登录请求
        this.loginRequestFun();
      },
      /**
       * 发起登录请求
       */
      loginRequestFun() {
        let {
          phone,
          password
        } = this;
        this.myRequest({
          method: 'post',
          url: '/api/login/account',
          data: {
            'accounts': phone,
            'pwd': password,
          }
        }).then((res) => {
          console.log('发起登录请求 res == ', res)

          // 记住密码
          this.recordPasswordFun();

          // 告知变化
          this.informChangeFun({
            status: 1
          });

          // 初始化账号表单
          this.initAccountFormFun();

          // // 跳转路由
          // this.myRouterPush({path: '/'});

        })
      },
      /**
       * 校验数据
       */
      verifyDataFun() {
        let {
          phone,
          password,
          login_checked
        } = this;
        console.log('校验数据 phone == ', phone)
        console.log('校验数据 password == ', password)

        // 电话号码
        if (!phone || !this.REGEXP.is_phone.test(phone)) {
          this.myMessage({
            message: '请输入有效的电话号码！'
          });
          return false;
        }

        // 密码
        if (!password) {
          this.myMessage({
            message: '请输入密码！'
          });
          return false;
        }

        // 登录协议
        if (!login_checked) {
          this.myMessage({
            message: '请阅读并勾选同意遵守用户协议和隐私协议！'
          });
          return false;
        }

        return true;
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .phone-login {
    $checked-color: #36B93F;
    $rowGutter: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .logo {
      position: relative;
      left: 3px;
      width: 50px;
      height: 50px;
      margin: 0 auto $rowGutter;

      // background: $theme-photo-background-color;
      .el-image {
        width: auto;
        height: 100%;
      }
    }

    .form-module {
      width: 70%;
      margin: $rowGutter auto;

      .field-item {
        margin: 20px 0;

        .input-box {
          $height: 40px;
          display: flex;
          height: $height;
          border: 1px solid #787878;
          border-radius: 4px;

          .input-icon {
            width: $height;
            height: $height;
            line-height: $height;
            text-align: center;

            .iconfont {
              font-size: 24px;
            }
          }

          &::v-deep .el-input {
            height: $height;

            .el-input__inner {
              border: none;
              height: $height;
              line-height: $height;
              padding: 0;
            }
          }
        }
      }

      .rest {
        position: relative;
        top: -8px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        .record-password {
          .fake-span {
            color: #AAAAAA;
          }

          &::v-deep .fake-checkbox {
            .el-checkbox__inner {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              overflow: hidden;

              &::after {
                height: 8px;
                left: 5px;
              }
            }

            .el-checkbox__input.is-focus .el-checkbox__inner {
              border-color: $checked-color;
            }

            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: $checked-color;
              border-color: $checked-color;
            }
          }
        }

        .forget-password {
          &>a {
            font-size: 14px;
          }

          .link-color {
            color: #2A72EA;
          }
        }
      }
    }

    .cot {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: $theme-lv2-color;
      margin: $rowGutter 0;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        width: 30%;
        height: 1px;
        background: $theme-photo-background-color;
      }

      &>span {
        display: inline-block;
        box-sizing: border-box;
        padding: 0 20px;
      }
    }

    .confirm {
      width: 70%;
      margin: $rowGutter auto;

      .fake-btn {
        width: 100%;
        font-size: 14px;
        color: #fff;
        border: 1px solid $common-number2-color;
        background: $common-number2-color;
        outline: none;

        &.el-button:hover,
        &.el-button:focus,
        &.el-button:active {
          color: none;
          border-color: none;
          background-color: none;
        }

        .iconfont {
          margin-right: 6px;
        }
      }

      .rest-control {
        margin-top: $rowGutter;
        text-align: center;
        font-size: 12px;
        color: #787878;

        .diff-style {
          color: $common-number1-color;
          cursor: pointer;
        }
      }

      &.login {
        .fake-btn {
          border: 1px solid $common-number1-color;
          background: $common-number1-color;
        }
      }
    }

    .agreement {
      margin-top: 50px;

      &::v-deep .fake-checkbox {
        .fake-span {
          font-size: 14px;
          color: #333;

          .diff-color {
            color: $checked-color;
          }
        }

        .el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: $checked-color;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: $checked-color;
          border-color: $checked-color;
        }
      }
    }
  }

</style>

/**
 * 加密、解密构造函数
 */

// 引入加密模块
const crypto = require('crypto');
const key = new Uint8Array(24); // 秘钥
const nonce = new Uint8Array(16); // 随机数

export default class MyLocker {
  constructor(name) {
    this.name = name;
  }
  /**
   * 加密
   * @param {String} data 要加密的数据
   * @returns {data:'加密数据', key: '秘钥', nonce: '随机数'};
   */
  aesEncrypt(data) {
    let data_type = typeof data;
    if(data_type != "string") throw new Error('TypeError: encrypted of value must is string type, current type' + data_type + '.');

    // const nonce = crypto.randomBytes(16); // 随机数
    // const key = crypto.randomBytes(24); // 秘钥
    // const key = new Uint8Array(24); // 秘钥
    // const nonce = new Uint8Array(16); // 随机数


    const cipher = crypto.createCipheriv('aes192', key, nonce ,{
      authTagLength: 16
    });
    var data = cipher.update(data, 'utf8', 'hex');
    data += cipher.final('hex');
    return {
      data,
      key,
      nonce,
    };
  }

  /**
   * 解密
   * @param {String} encrypted 解密数据/要解密的数据
   * @param {String} key 秘钥/与加密时的秘钥一致
   * @param {String} nonce 随机数
   * @returns 解密数据
   */
  aesDecrypt(encrypted, keys, nonces) {
    try {
      const decipher = crypto.createDecipheriv('aes192', key, nonce);
      var decrypted = decipher.update(encrypted, 'hex', 'utf8');
      decrypted += decipher.final('utf8');
      return decrypted;
    } catch(err) {
      console.log(err)
    }
  }
}

/**
 * 示例
 */
// var str = '123456';
// var encrypted = aesEncrypt(str);
// let { data, key, nonce } = encrypted;
// console.log(encrypted);
// var decrypted = aesDecrypt(data, key, nonce);

// console.log('Plain text: ' + data);
// console.log('nonce: ' + nonce);
// console.log('Encrypted text: ' + encrypted);
// console.log('Decrypted text: ' + decrypted);
